import React from "react";
import {lazy} from "react"
import { Route, Switch } from "react-router-dom";
import { Redirect } from "react-router-dom/cjs/react-router-dom.min";
const Index=lazy(()=>import("../components/404"))
const HostingInformation=lazy(()=>import("../components/private/hosting-information"))
// const InformationMaterialPage=lazy(()=>import("../components/private/information-material"))
//  const ManagedHosting=lazy(()=>import("../components/private/managed-hosting"))
const HostingBilling=lazy(()=>import("../components/private/managed-hosting-billing"))
const Service=lazy(()=>import("../components/private/service"))
// const SubPlanBilling=lazy(()=>import("../components/private/subscription-plan-billing"))
// const WebsiteDesignPackage=lazy(()=>import("../components/private/web-design-package"))
// const WebDomainEmail=lazy(()=>import("../components/private/web-domain-email"))
const Mainpage=lazy(()=>import("../components/private/main"))
const ViewTemplatePage=lazy(()=>import("../components/private/viewTemplates"))

// const  WebsiteRedesign=lazy(()=>import("../components/private/website-desgin-inspiration"))

const UserDashboard=lazy(()=>import("../components/UserDashboard/userdashboard"))
const HostingMainpage=lazy(()=>import("../components/private/manage-hosting"))
const AddonMainPage=lazy(()=>import("../components/private/manage-addon"))
export default function Approute({ setPage }) {
  return (
    <Switch>

      <Redirect exact from="/" to="/user-dashboard/new-order" />
      {/* <Route exact path="/subscription/billing" component={SubPlanBilling} />
      <Route exact path="/subscription/web-package" component={WebsiteDesignPackage} />
      */}
      {/* <Route exact path="/subscription/web-domain" component={WebDomainEmail} />
      <Route exact path="/subscription/web-design" component={WebsiteRedesign} />
      <Route exact path="/subscription/information" component={InformationMaterialPage} />
      */}

      <Route
        exact
        path="/user-dashboard/new-order/website"
        component={Mainpage}
      />
      <Route
        exact
        path="/user-dashboard/new-order/subscription"
        component={HostingMainpage}
      />
      <Route exact path="/service" component={Service} />
      <Route
        exact
        path="/user-dashboard/new-order/addons"
        component={AddonMainPage}
      />
      <Route
        exact
        path="/subscription/hosting/bill"
        component={HostingBilling}
      />
      <Route
        path="/subscription/hosting/bill/information"
        component={HostingInformation}
      />
      <Route
        exact
        path="/user-dashboard/view-template"
        component={ViewTemplatePage}
      />
      <Route path="/user-dashboard" component={UserDashboard} />
      <Route component={Index} />
      {/* <Route exact path="/dashboard" component={Dashboard} />
      <Route exact path="/dashboard/order" component={DashboardOrder} /> */}
    </Switch>
  );
}