
const initialState = {

   userId:'',
   email:'',
   personaId:'',
   domainType:'own',
   searchDomain:'',
   templateId:'',
   businessName:'',
   referenceWebsite:'',
   uploadLogo:'',
   businessDescription:'',
   packageId:'',
   packagePrice:'',
   addOns:'',
   types:[],
   currentStep:1,
   subscription:[{checked:false    , id:'12'}],
   packages:[],
   orderName:''

}


const NewManageReducer = (state = initialState,  action) => {
 
     switch ( action.type ){
    
        case 'UPDATE_HOSTING_DOMAIN' :
         
        return {
           ...state,
           ...action.payload,
        }

        case 'UPDATE_TEMPLATE_DOMAIN' :
        
        const { index, selected } = action.payload;
        const newPackages = [...state.packages]; // Copy the original array
        newPackages[index] = selected; // Update the specific element
  
        return {
          ...state,
          packages: newPackages, // Update the state
        };
       
      
        case 'DELETE_PACKAGE' :
         
        return  state = initialState
       
  default : return state;

}
}

export default NewManageReducer;


