

const ADD_DATA = (payload) => {
  return {
    type: "UPDATE_STATE",
    data: payload,
  };
};

const updateDomain = (payload) => {
 
  return { 
     
    type : 'UPDATE_DOMAIN', 
    data: payload
      
 }

}

const updateHostingDomain = (payload) => {
  
   return { 
      
     type : 'UPDATE_HOSTING_DOMAIN', 
     data: payload
       
  }
 
 }
 


const updateTemplate = ( index  , selected ) => {

  return { 
     
    type : 'TEMPLATE_DOMAIN', 
    payload : {
      index,
      selected,
    } 
      
 }

}

const updateHostingTemplate = ( index  , selected ) => {

  return { 
     
    type : 'UPDATE_TEMPLATE_DOMAIN', 
    payload : {
      index,
      selected,
    } 
      
 }

}


const updatePackage = (payload) => {

  return { 
     
    type : 'PACKAGE_UPDATE', 
    data: payload
      
 }

}

const updateSub = (payload) => {

  return { 
     
    type : 'SUBSCRIPTION_UPDATE', 
    data: payload
      
 }

}

const updateInfo = (data) => {

  return { 
     
    type : 'INFORMATION_UPDATE', 
    payload: data
      
 }

}

const deleteAll = () => {

  return { 
     
    type : 'DELETE_STATE', 
 }

}

const updateManagePackage = (data) => {

  return { 
     
    type : 'PACKAGE',
    payload: data 
 }

}
const deletePackage = () => {

  return { 
    type : 'DELETE_PACKAGE', 
 }

}

const addingRegion = (payload) => {
  return {
    type: 'ADDING_REGION',
    payload,
  };
};


export default ADD_DATA;

export   { updateDomain , updateTemplate , updatePackage , updateSub , updateInfo , updateHostingDomain,updateHostingTemplate , deleteAll, updateManagePackage, deletePackage,addingRegion}