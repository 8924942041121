import React from "react";
import dotenv from "dotenv";
import './App.css';
import Routes from './routes';
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { getRegion } from "./utils/utils";
import { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { addingRegion } from './Redux/Actions/action';
dotenv.config();
function App() {
  const dispatch=useDispatch()
  const myCountry = useSelector((state) => state.NewHostingReducer.region);
  const[country,setCountry]=useState('')

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await getRegion();
       dispatch(addingRegion(res))
        setCountry(res);
      } catch (error) {
        console.error('Error fetching region:', error);
      }
    };
    if (!myCountry) {
      fetchData();
    }
  }, [myCountry]);
//live
//  const PublicKey =
//    "pk_live_51GvRZpA6POsBijtx8pXLkP7AUU2857Iv01jPsKTBOlyFfXrA2GYh49onayvYmTaGu8XKcCQELn3IBALCifdVDc4I00VgMdNbvl";


  const stripeTestPromise = loadStripe(
    process.env.REACT_APP_STRIPE_PUBLIC_KEY
  );
  return (
    <div className="App">
      <Elements stripe={stripeTestPromise} >
        <Routes />
      </Elements>
    </div>
  );
}

export default App;
