import { getRegion } from '../utils/utils';
const initialState = {
   userId: '',
   email: '',
   personaId: '',
   domainType: 'own',
   searchDomain: '',
   templateId: '',
   businessName: '',
   referenceWebsite: '',
   uploadLogo: '',
   businessDescription: '',
   packageId: '',
   packagePrice: '',
   addOns: '',
   types: [],
   active: 'new',
   hostingCurrentStep: 1,
   addCurrentStep: 1,
   currentStep: 1,
   addOnsPackages: [],
   hostingSubscription: [{ checked: false, id: '12' }],
   hostingPackages: [],
   subscription: [{ checked: false, id: '12' }],
   packages: [],
   orderName: '',
   region: '',
}
// const assyncFun = async () => {
//    await getRegion().then((res) => {
//       return res
//    })
//    // return data
// }
// (async () => {
//    await getRegion().then((res) => {
//       
//    })

// })()
// getRegion().then((res) => {
   
//    
// })

const NewHostingReducer = (state = initialState, action) => {

   switch (action.type) {

      case 'UPDATE_DOMAIN':

         return {
            ...state,
            ...action.data,

         }
      case 'TEMPLATE_DOMAIN':

         const { index, selected } = action.payload;
         const newPackages = [...state.packages]; // Copy the original array
         newPackages[index] = selected; // Update the specific element

         return {
            ...state,
            packages: newPackages, // Update the state
         };
      case 'PACKAGE_UPDATE':

         return {
            ...state,
            ...action.data,
         }

      case 'SUBSCRIPTION_UPDATE':

         return {
            ...state,
            ...action.data,
         }

      case 'ADDING_REGION':
         return {
            ...state,
            region: action.payload,
         };

      case 'DELETE_STATE':

         return state = initialState

      default: return state;

   }
}

export default NewHostingReducer;


