import { combineReducers } from 'redux';
import HostingReducer from '../Reducers/orderreducer';
import NewHostingReducer from '../hostingReducers';
import ADDReducer from '../Reducers/reducer';
import NewManageReducer from '../manageReducers';

export default combineReducers({
    NewHostingReducer,
    NewManageReducer,
    
})
