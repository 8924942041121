const Auth = {
  isAuthenticated: true,
  authenticate() {
    this.isAuthenticated = true;
  },
  logout() {
    this.isAuthenticated = false;
    if (
      (localStorage.removeItem("@appToken"),
      localStorage.removeItem("ID"),
      localStorage.removeItem("data"),
      localStorage.removeItem("orderId"),
      localStorage.removeItem("OrderId"),
      localStorage.removeItem("EMAIL"),
      // localStorage.removeItem("StripeId"),
      localStorage.removeItem("stripeId"))
      
    ) {
      window.location.href("/home");
      return true;
    }
    return false;
  },
  //
  getAuth() {
    if (localStorage.getItem("@appToken")) {
      return true;
    }
    return false;
  },
};
export default Auth;
