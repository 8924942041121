import { createStore } from "redux";
import { persistReducer, persistStore } from "redux-persist"; // imports from redux-persist
import storage from "redux-persist/lib/storage";
import rootReducer from "./Reducers";

const persistConfig = {
  // configuration object for redux-persist
  key: "root",
  storage, // define which storage to use
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = createStore(
  persistedReducer,
  window.__REDUX_DEVTOOLS_EXTENSION__ &&
    window.__REDUX_DEVTOOLS_EXTENSION__({
      serialize: true,
    })
);
const persistor = persistStore(store);

// function DataProvider({ children }) {
//   return <Provider store={store}>{children}</Provider>;
// }
export { store, persistor };

